const toggle = el => {
  if (el.style.display === "none") {
    el.style.display = "block";
  } else {
    el.style.display = "none";
  }
};

class OrderForm {
  constructor(form) {
    this.form = form;

    this.ribbonText = this.form.querySelector(".js-ribbon-text");
    this.ribbonRemaining = this.form.querySelector(".js-ribbon-remaining");

    this.invoiceToggle = this.form.querySelector(".js-invoice-toggle");
    this.invoiceFields = this.form.querySelectorAll(".js-invoice-field");

    this.invoiceDetailToggle = this.form.querySelector(
      ".js-invoice-detail-toggle"
    );

    this.invoiceDetailFields = this.form.querySelectorAll(
      ".js-invoice-detail-field"
    );

    this.ribbonText.addEventListener("keyup", e => this.onKeyPress(e));

    this.invoiceToggle.addEventListener("click", e =>
      this.invoiceToggleClicked(e)
    );

    this.invoiceDetailToggle.addEventListener("click", e =>
      this.toggleInvoiceDetailFields(e)
    );
  }

  onKeyPress(e) {
    const text = e.target.value;
    const numberOfCharacters = text.length;
    const limit = 75;
    const remainder = parseInt(limit - numberOfCharacters, 10);

    this.ribbonRemaining.textContent = remainder;
  }

  invoiceToggleClicked(e) {
    if (e.target.checked) {
      this.invoiceDetailToggle.parentNode.parentNode.style.display = "block";
      this.invoiceDetailToggle.style.display = "inline";
      this.invoiceDetailToggle.checked = true;
      this.invoiceDetailToggle.disabled = false;

      this.toggleInvoiceFields();
    } else {
      this.invoiceDetailToggle.parentNode.parentNode.style.display = "none";
      this.invoiceDetailToggle.style.display = "none";
      this.invoiceDetailToggle.checked = false;
      this.invoiceDetailToggle.disabled = true;

      this.toggleInvoiceFields();
      this.hideInvoiceDetails();
    }
  }

  hideInvoiceDetails() {
    this.invoiceDetailFields.forEach(el => {
      el.style.display = "none";
    });
  }

  toggleInvoiceFields() {
    this.invoiceFields.forEach(toggle);
  }

  toggleInvoiceDetailFields() {
    this.invoiceDetailFields.forEach(toggle);
  }
}

export default OrderForm;
