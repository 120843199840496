import Rails from "@rails/ujs";
import Turbo from "@hotwired/turbo";

import "bootstrap/js/src/util";
import "bootstrap/js/src/alert";

import "./application/map";
import OrderForm from "./application/order-form";

Rails.start();

document.addEventListener("turbo:load", () => {
  const orderFormElement = document.querySelector("form.js-order-form");

  if (orderFormElement !== null) {
    new OrderForm(orderFormElement);
  }

  document.querySelectorAll(".navigation-trigger").forEach((el) => {
    el.addEventListener("click", () => {
      document
        .querySelectorAll(".navigation-links, .navigation-trigger")
        .forEach((el) => el.classList.toggle("is-open"));
    });
  });
});
