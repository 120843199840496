/* global google, iconURL */

const styles = [
  {
    featureType: "administrative.locality",
    elementType: "all",
    stylers: [
      { hue: "#2c2e33" },
      { saturation: 7 },
      { lightness: 19 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      { hue: "#ffffff" },
      { saturation: -100 },
      { lightness: 100 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      { hue: "#ffffff" },
      { saturation: -100 },
      { lightness: 100 },
      { visibility: "off" },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: 31 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: 31 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: -2 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      { sue: "#e9ebed" },
      { saturation: -90 },
      { lightness: -8 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      { hue: "#e9ebed" },
      { saturation: 10 },
      { lightness: 69 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      { hue: "#e9ebed" },
      { saturation: -78 },
      { lightness: 67 },
      { visibility: "simplified" },
    ],
  },
];

window.initMap = () => {
  const mapOptions = {
    scrollwheel: false,
    zoom: 10,
    center: new google.maps.LatLng(50.9066275, 3.0187468),
    disableDefaultUI: false,
    draggable: false,
    mapTypeControlOptions: {
      mapTypeIds: [google.maps.MapTypeId.ROADMAP, "bestfromgoogle"],
    },
  };

  const map = new google.maps.Map(document.getElementById("map"), mapOptions);

  const styledMapOptions = {
    name: "Vandecandelaere - Spruytte - Vandecandelaere en Casier",
  };

  const jayzMapType = new google.maps.StyledMapType(styles, styledMapOptions);

  map.mapTypes.set("bestfromgoogle", jayzMapType);
  map.setMapTypeId("bestfromgoogle");

  // Passendale

  const passendaleInfoWindow = new google.maps.InfoWindow({
    content: `<div class="map-popup">
      <h5>Passendale</h5>
      <p>
        Westrozebekestraat 25A<br>
        8980 Passendale<br>
        Tel. +32 (0) 51 770 759<br>
        Fax. +32 (0) 51 780 615<br>
        <a href="mailto:info@vandecandelaere-spruytte.be" target="_blank" title="E-mail ons">info@vandecandelaere-spruytte.be</a>
      </p>
    </div>`,
  });

  const passendaleMarker = new google.maps.Marker({
    map,
    position: new google.maps.LatLng(50.9066275, 3.0187468),
    icon: {
      url: iconURL,
      scaledSize: new google.maps.Size(25, 37.6),
      anchor: new google.maps.Point(12.5, 16.8),
    },
    title: "Passendale",
    zIndex: 3,
  });

  google.maps.event.addListener(passendaleMarker, "click", () => {
    passendaleInfoWindow.open(map, passendaleMarker);
  });

  // Roeselare

  const roeselareInfoWindow = new google.maps.InfoWindow({
    content: `<div class="map-popup">
      <h5>Roeselare</h5>
      <p>
        Kleine Bruanestraat 19<br>
        8800 Roeselare<br>
        Tel. +32 (0) 51 240 103<br>
        <a href="mailto:info@vandecandelaere-spruytte.be" target="_blank" title="E-mail ons">info@vandecandelaere-spruytte.be</a>
      </p>
    </div>`,
  });

  const roeselareMarker = new google.maps.Marker({
    map,
    position: new google.maps.LatLng(50.9419291, 3.1438954),
    icon: {
      url: iconURL,
      scaledSize: new google.maps.Size(25, 37.6),
      anchor: new google.maps.Point(12.5, 16.8),
    },
    title: "Roeselare",
    zIndex: 3,
  });

  google.maps.event.addListener(roeselareMarker, "click", () => {
    roeselareInfoWindow.open(map, roeselareMarker);
  });

  // Staden

  const stadenInfoWindow = new google.maps.InfoWindow({
    content: `<div class="map-popup">
      <h5>Staden</h5>
      <p>
        Charles Ampestraat 2A<br>
        8840 Staden<br>
        Tel. +32 (0) 51 701 253<br>
        <a href="mailto:inlichtingen@uitvaartzorgcasier.be" target="_blank" title="E-mail ons">inlichtingen@uitvaartzorgcasier.be</a>
      </p>
    </div>`,
  });

  const stadenMarker = new google.maps.Marker({
    map,
    position: new google.maps.LatLng(50.9788556, 3.0080007),
    icon: {
      url: iconURL,
      scaledSize: new google.maps.Size(25, 37.6),
      anchor: new google.maps.Point(12.5, 16.8),
    },
    title: "Staden",
    zIndex: 3,
  });

  google.maps.event.addListener(stadenMarker, "click", () => {
    stadenInfoWindow.open(map, stadenMarker);
  });
};
